<template>
    <section>
        <app-header-bar/>

        <!-- As soon as this element scrolls out of the viewport, consider page "scrolled": -->
        <app-scroll-detector/>

        <main class="content">
            <router-view/>
        </main>

        <ui-modal align-top v-bind:isOpen.sync="settingsModalVisible">
            <template v-slot:header>
                {{$t('settings.title')}}
            </template>
            <app-settings/>
        </ui-modal>

        <app-toast-container/>
        <app-up-button/>
    </section>
</template>

<script>
import AppScrollDetector from '~/components/AppScrollDetector.vue';
import AppToastContainer from '~/components/AppToastContainer.vue';
import AppHeaderBar from '~/components/AppHeaderBar.vue';
import AppSettings from '~/components/settings/AppSettings.vue';
import AppUpButton from '~/components/AppUpButton.vue';

export default {
    data() {
        return {
            settingsModalVisible: false,
        };
    },

    provide() {
        return {
            openSettings: () => {
                this.settingsModalVisible = true;
            },
        };
    },

    metaInfo() {
        return {
            // Add alternate lang links to every page:
            // @see https://developers.google.com/search/docs/specialty/international/localized-versions
            link: this.$i18n.availableLocales.map(lang => Object.freeze({
                rel: 'alternate',
                hreflang: lang,
                href: this.$localizedUrl(lang),
            })),
        };
    },

    components: {
        AppScrollDetector,
        AppToastContainer,
        AppHeaderBar,
        AppSettings,
        AppUpButton,
    },
};
</script>
