<template>
    <div class="chart-bar">
        <ui-chart-legend v-if="!hideLegend" v-bind:items="legend" />

        <div class="chart-bar__graph">
            <canvas ref="canvas" />
            <ui-chart-tooltip ref="tooltip" v-bind="tooltip" />
        </div>
    </div>
</template>

<script>
import { loadChartJs } from '~/lib/Chart.js';
import ColorSchemeMixin from './mixins/changeColorScheme.js';
import { createTooltip, createTooltipStub } from './tooltip.js';
import { prefixNumber, createGradientFromContext } from './helpers.js';
import UiChartTooltip from './UiChartTooltip.vue';
import UiChartLegend from './UiChartLegend.vue';

const randomValues = [
    { x: 0, y: 1.2452 },
    { x: 1, y: 7.434235 },
    { x: 2, y: 6.034235 },
    { x: 3, y: 5.235 },
    { x: 4, y: 7.342325 },
    { x: 5, y: 3.2434325 },
    { x: 6, y: 2.325235236 },
    { x: 7, y: 4.7453634 },
    { x: 8, y: 7.3442 },
    { x: 9, y: 5.2423423 },
    { x: 10, y: 10.32424 },
    { x: 11, y: 9 },
    { x: 12, y: 7 },
];

const randomLabels = randomValues.map(_ => ' '.repeat(1));

/* eslint no-param-reassign: "off" */
const skipFirstLabel = (scale) => {
    if (scale.ticks.length > 0) {
        scale.ticks[0].label = '';
    }
};

export default {
    chart: undefined,

    props: {
        labels: {
            type: Array,
            default: () => randomLabels,
        },
        datasets: {
            type: Array,
            default: () => [{
                data: randomValues,
                borderWidth: 1.5,
                fill: true,
                isSkeleton: true,
                xAxisId: 'skeleton',
                parsing: false,
            }],
        },
        type: {
            type: String,
            default: 'line',
        },
        hideLegend: Boolean,
    },

    data() {
        return {
            tooltip: {},
            legend: [],
            timeUnit: 'day',
        };
    },

    watch: {
        datasets(newData) {
            loadChartJs().then(() => {
                const chart = this.$options.chart;

                chart.config.options.scales.x.display = true;
                chart.config.options.scales.y.ticks.display = true;
                chart.config.options.scales.skeleton.display = false;
                chart.config.options.animation.duration = 700;

                // cannot set it in default config:
                chart.config.options.elements.line.backgroundColor = newData[0].backgroundGradient
                    ? createGradientFromContext
                    : 'transparent';

                // optimizing performance:
                chart.config.options.elements.line.cubicInterpolationMode = this.labels.length > 90
                    ? 'default'
                    : 'monotone';

                chart.data.labels = this.labels;
                chart.data.datasets = newData;

                const timeUnit = this.getTimeUnit(this.labels.at(0), this.labels.at(-1));
                chart.config.options.scales.x.time.unit = timeUnit;

                chart.update();
            });
        },
    },

    beforeDestroy() {
        if (this.$options.chart) {
            this.$options.chart.destroy();
        }

        this.$options.chart = undefined;
    },

    async mounted() {
        const Chart = await loadChartJs();

        /* eslint no-underscore-dangle: "off" */
        const BarChart = new Chart(this.$refs.canvas, {
            plugins: [{
                afterDraw: (chart) => {
                    if (chart.tooltip?._active?.length) {
                        const x = chart.tooltip._active[0].element.x;
                        const yAxis = chart.scales.y;
                        const ctx = chart.ctx;

                        ctx.save();
                        ctx.setLineDash([8, 5]);
                        ctx.beginPath();
                        ctx.moveTo(x, yAxis.top);
                        ctx.lineTo(x, yAxis.bottom);
                        ctx.lineWidth = 1;
                        ctx.strokeStyle = 'rgba(147, 180, 155, 0.2)';
                        ctx.stroke();
                        ctx.restore();
                    }
                },
            }],
            type: this.type,
            data: {
                labels: this.labels,
                datasets: this.datasets,
            },
            options: {
                normalized: true,
                elements: { line: {} },
                animation: { duration: 0 },
                layout: {
                    autoPadding: false,
                    padding: {
                        top: 20,
                        right: 6,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    skeleton: {
                        position: 'bottom',
                        display: true,
                        afterTickToLabelConversion: skipFirstLabel,
                        type: 'category',
                        ticks: {
                            color: this.style.skeletonColor,
                            backdropColor: this.style.skeletonColor,
                            showLabelBackdrop: true,
                            maxTicksLimit: 9,
                        },
                    },
                    x: {
                        type: 'time',
                        display: false,
                        grid: {
                            display: this.type === 'line',
                            color: this.style.gridColor,
                        },
                        afterTickToLabelConversion: skipFirstLabel,
                        ticks: {
                            maxTicksLimit: 5,
                        },
                    },
                    y: {
                        grid: {
                            display: this.type === 'bar',
                            color: this.style.gridColor,
                        },
                        position: 'right',
                        beginAtZero: false,
                        alignToPixels: true,
                        border: { display: true },
                        ticks: {
                            display: false,
                            maxTicksLimit: 7,
                            callback: prefixNumber,
                        },
                    },
                },

                plugins: {
                    tooltip: createTooltip({
                        element: this.$refs.tooltip.$el,
                        onChange: newTooltip => Object.assign(this.tooltip, newTooltip),
                    }),

                    htmlLegend: {
                        onReady: props => this.$set(this, 'legend', props),
                    },
                },
            },
        });

        this.$options.chart = BarChart;
        this.tooltip = createTooltipStub(BarChart);
    },

    methods: {
        getTimeUnit(oldestTimestamp, newestTimestamp) {
            const limit = 60 * 60 * 24 * 2 * 1000; // 3 days
            const difference = newestTimestamp - oldestTimestamp;

            return difference > limit ? 'day' : 'hour';
        },
    },

    components: {
        UiChartTooltip, UiChartLegend,
    },

    mixins: [ColorSchemeMixin],
};
</script>

<style lang="scss">
.chart-bar {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    &__graph {
        position: relative;
        margin: 0 0 10px -8px;
        box-sizing: border-box;
        flex-grow: 1;
        color: #313131;
    }
}
</style>
