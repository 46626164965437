<template>
    <tbody>
        <tr>
            <td class="tx-mobile-flex-content" colspan="10" style="font-size: 13px;">
                <ui-link class="tx-table-cell-icon tx-table-cell-icon--service" v-bind:to="transactionRoute">
                    <icon-out/>
                </ui-link>

                <div class="tx-mobile-content">
                    <div class="tx-mobile-content__top">
                        <div class="tx-mobile-content-row">
                            <div class="tx-mobile-content-row__left">
                                <span v-if="isExternal || from === null" class="muted">
                                    {{$t('address.tx_table.external')}}
                                </span>

                                <template v-else>
                                    <span class="muted">
                                        {{$t('address.all_tx_table.from')}}&nbsp;
                                    </span>

                                    <ui-address v-bind:address="from"/>
                                </template>
                            </div>

                            <div class="tx-mobile-content-row__right">
                                <span v-if="amount === null || isExternal" class="muted">
                                    {{$t('common.empty')}}
                                </span>

                                <template v-else-if="amount">
                                    {{$ton(amount)}} TON
                                </template>
                            </div>
                        </div>

                        <div class="tx-mobile-content-row">
                            <div class="tx-mobile-content-row__left">
                                <span class="muted">
                                    {{$t('address.all_tx_table.to')}}&nbsp;
                                </span>

                                <ui-address v-if="to" v-bind:address="to"/>

                                <template v-else>
                                    {{$t('address.tx_table.nowhere')}}
                                </template>
                            </div>

                            <div class="tx-mobile-content-row__right">
                                <ui-timeago v-bind:timestamp="createdAt * 1000" class="muted"/>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import IconOut from '@img/icons/tonscan/transactions/tx-out.svg?vue';

export default {
    props: {
        hash: String,
        createdAt: Number,
        from: String,
        to: String,
        isExternal: Boolean,
        amount: String,
    },

    computed: {
        transactionRoute() {
            return {
                name: 'tx',
                params: {
                    hash: this.hash,
                },
            };
        },
    },

    components: {
        IconOut,
    },
};
</script>
