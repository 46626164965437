<template>
    <section class="stats-section">
        <div style="margin-top: 0;" class="card information-block"
            v-for="(item, index) of informationBlocks"
            v-bind:key="`stats_infoblock_${index}`"
            v-bind:data-index="index">
            <div class="chart-box" v-if="item.value === 0">
                <header>
                    <span class="skeleton">User transaction count</span>
                </header>
                <div class="chart-box__value">
                    <span class="skeleton">3,414,166,606</span>
                </div>
                <footer>
                    <span class="skeleton">123 transactions per second</span>
                </footer>
            </div>

            <div v-else class="chart-box">
                <header v-text="item.header"/>
                <div class="chart-box__value">
                    <component
                        v-if="item.component"
                        v-bind:is="item.component"
                        v-bind:value="item.value"/>

                    <template v-else>
                        <icon-ton v-if="item.showTonIcon" class="ton-icon"/> {{item.value}}
                    </template>
                </div>
                <footer v-text="item.description"/>
            </div>
        </div>

        <div class="price-container">
            <chart-price/>
        </div>

        <div class="cotract-types-container">
            <chart-contract-types/>
        </div>

        <div class="address-count-container">
            <chart-address-count v-on:supply="setTotalSupply"/>
        </div>

        <div class="transaction-count-container">
            <chart-transaction-count/>
        </div>

        <div class="staking-container">
            <chart-staking v-bind:validators-amount="validatorCount"/>
        </div>

        <div class="validation-status-container">
            <validation-status
                v-bind:cycle-start="validationCycleStart"
                v-bind:cycle-end="validationCycleEnd"/>
        </div>
    </section>
</template>

<script>
import IconTon from '@img/icons/tonscan/ton-24.svg?inline';
import { MULTIPLIER } from '~/helpers';
import { getBlockchainMarketAnal, blockAnal } from '~/api/extenderContracts.js';
import { getValidatorsStats } from '~/api/elections.js';
import { getStatus } from '~/api/tontech.js';
import { getPreviousBlocks } from '~/api';
import UiAnimatedNumber from '~/components/UiAnimatedNumber.vue';
import ChartContractTypes from './ChartContractTypes.vue';
import ChartTransactionCount from './ChartTransactionCount.vue';
import ChartAddressCount from './ChartAddressCount.vue';
import ChartPrice from './ChartPrice.vue';
import ChartStaking from './ChartStaking.vue';
import ValidationStatus from './ValidationStatus.vue';

const formatter = new Intl.NumberFormat('en');

export default {
    data() {
        return {
            currentHeight: 0,
            blockTime: 3.5,
            tps: 0,
            txCount: 0,
            circulation: 0,
            circulationPercent: 0,
            validatorCount: 0,
            validationCycleStart: 0,
            validationCycleEnd: 0,
            totalSupply: 0,
        };
    },

    async mounted() {
        this.loadBlockAnalytics();

        const [validation, tontech] = await Promise.all([
            getValidatorsStats({ limit: 1 }).then(([firstItem]) => firstItem),
            getStatus(),
            getBlockchainMarketAnal(),
        ]);

        const last_data_obj = tontech[tontech.length - 1];
        this.circulation = formatter.format(Math.floor(last_data_obj.circulating_supply / MULTIPLIER));
        this.circulationPercent = Math.round((last_data_obj.circulating_supply / last_data_obj.total_supply) * 100);

        this.validatorCount = validation.validator_count;
        this.validationCycleStart = validation.validation_cycle_start;
        this.validationCycleEnd = validation.validation_cycle_end;
    },

    computed: {
        informationBlocks() {
            return [{
                header: this.$t('stats.masterchain_height'),
                description: this.$t('stats.block_time', { time: this.blockTime }),
                value: this.currentHeight,
                component: UiAnimatedNumber,
            }, {
                header: this.$t('stats.transactions_count'),
                description: this.$tc('stats.transactions_per_second', this.tps),
                value: this.txCount,
                component: UiAnimatedNumber,
            }, {
                header: this.$t('stats.circulation'),
                description: this.$t('stats.percent_total_supply', { total: this.circulationPercent }),
                value: this.circulation,
                showTonIcon: true,
            }, {
                header: this.$t('stats.total_supply'),
                description: this.$t('stats.percent_inflation_rate'),
                value: this.totalSupply,
                showTonIcon: true,
            }];
        },
    },

    methods: {
        async loadBlockAnalytics() {
            const takeCount = 48;

            const stats = await blockAnal();
            const previousBlocks = await getPreviousBlocks({ limit: takeCount });

            const mcBlocks = previousBlocks.filter(b => b.workchain === -1).slice(0, 2);
            const wcBlocks = previousBlocks.filter(b => b.workchain === 0).slice(0, 2);

            this.currentHeight = previousBlocks[0].seqno;
            this.blockTime = mcBlocks[0].gen_utime - mcBlocks[1].gen_utime;
            this.txCount = stats.trans_ord_count;

            const tps = previousBlocks.reduce((acc, val) => acc + val.tx_count, 0) / takeCount;
            this.tps = tps.toFixed(2);

            if (Number.isInteger(this.totalTx)) {
                this.txCount += mcBlocks[0].tx_count;
                this.txCount += wcBlocks[0].tx_count;
            }

            setTimeout(() => this.loadBlockAnalytics(), this.blockTime * 1000);
        },

        setTotalSupply(supply) {
            this.totalSupply = formatter.format(
                Math.round(supply / MULTIPLIER),
            );
        },
    },

    components: {
        ChartContractTypes,
        ChartTransactionCount,
        ChartAddressCount,
        ChartPrice,
        ChartStaking,
        ValidationStatus,
        IconTon,
    },
};
</script>

<style lang="scss">
.stats-section {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-rows: 120px 370px 340px repeat(2, 380px);
    grid-gap: 20px 20px;
}

.chart-box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 16px;
    flex: 1;

    header {
        margin-bottom: 8px;
        color: var(--card-header-color);
    }

    &__value {
        font-size: 28px;
        font-weight: 500;
    }

    footer {
        opacity: .3;
    }
}

@for $i from 0 through 3 {
    .information-block[data-index='#{$i}'] {
        grid-column: #{$i * 3 + 1} / span 3;
    }
}

.ton-icon {
    fill: currentColor;
    width: 28px;
    height: 28px;
    margin-right: 2px;
    opacity: .3;
    transform: translate(-3px, 3px);
}

.cotract-types-container {
    grid-column: 1 / 6;
}

.address-count-container {
    grid-column: 6 / span 7;
}

.price-container,
.transaction-count-container,
.staking-container,
.validation-status-container {
    grid-column: 1 / 13;
}

@media screen and (max-width: 1099px) {
    .stats-section {
        grid-template-rows: 108px 108px 400px 360px repeat(2, 300px) 400px auto;
    }

    .information-block[data-index='0'],
    .information-block[data-index='2'] {
        grid-column: 1 / 7;
    }

    .information-block[data-index='1'],
    .information-block[data-index='3'] {
        grid-column: 7 / 13;
    }

    .cotract-types-container,
    .address-count-container {
        grid-column: 1 / 13;
    }
}

@media screen and (max-width: 599px) {
    .ton-icon {
        width: 25px;
        height: 25px;
    }
    .stats-section {
        grid-gap: 0;
        grid-template-rows:
            repeat(4, 108px)
            520px
            570px
            repeat(2, 300px)
            520px
            auto;

        .card-title {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .chart-box {
        &__value {
            font-size: 24px;
        }
    }

    .price-container,
    .cotract-types-container,
    .address-count-container,
    .transaction-count-container,
    .staking-container,
    .validation-status-container {
        padding-top: 20px;
    }

    .chart-bar {
        padding-left: 0!important;
    }

    .stats-section {
        .information-block {
            border-bottom-width: 0;
            border-radius: 0;
        }

        .information-block[data-index='0'] {
            border-top-right-radius: 12px;
            border-top-left-radius: 12px;
        }

        .information-block[data-index='3'] {
            border-bottom-right-radius: 12px;
            border-bottom-left-radius: 12px;
            border-bottom-width: 1px;
        }

        .information-block[data-index='0'],
        .information-block[data-index='1'],
        .information-block[data-index='2'],
        .information-block[data-index='3'] {
            grid-column: 1 / 13;
        }
    }
}
</style>
