<template>
    <section>
        <div class="card">
            <div class="card-title">
                {{$t('single_nominator.pool')}}
                <a class="card-title-help" target="_blank"
                    v-bind:href="$t('single_nominator.title_help_link')">
                    <icon-title-help class="card-title-help__icon"/>
                    {{$t('common.what_is_this')}}
                </a>
            </div>

            <div style="flex-grow: 1;">
                <div class="card-row">
                    <div class="card-row__name" v-text="$t('common.address')"/>
                    <div class="card-row__value">
                        <ui-copy-button v-bind:copy="address" v-bind:successMessage="$t('address.info.copy_success')">
                            {{address}}
                        </ui-copy-button>

                        <span class="card-main-qr-button" v-on:click="qrModalVisible = true">
                            <icon-qr class="card-main-qr-button__icon"/>
                        </span>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card-row__name" v-text="$t('address.info.balance')"/>
                    <div class="card-row__value" v-if="wallet.balance == '0' || wallet.balance">
                        {{$ton(wallet.balance)}} TON

                        <template v-if="wallet.balance != '0'">
                            <span v-if="$store.state.exchangeRate" style="color: #717579">
                                ≈ <ui-fiat v-bind:tonValue="wallet.balance"/>
                            </span>
                        </template>
                    </div>
                    <div v-else class="card-row__value">
                        <span class="skeleton">00000 TON ≈ 00000 USD</span>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card-row__name" v-text="$t('single_nominator.owner')"/>
                    <div class="card-row__value">
                        <ui-address v-if="poolInfo.owner_address" v-bind:address="poolInfo.owner_address"/>
                        <span v-else class="skeleton">EQDCH6....Loh</span>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card-row__name" v-text="$t('nominator_pool.validator')"/>
                    <div class="card-row__value" style="display: flex; align-items: center;">
                        <template v-if="poolInfo.validator_address">
                            <ui-address v-bind:address="poolInfo.validator_address"/>
                        </template>

                        <span v-else class="skeleton">EQDCH6....Loh</span>
                    </div>
                </div>
            </div>
        </div>

        <ui-modal class="qr-modal" v-bind:isOpen.sync="qrModalVisible">
            <ui-qr show-logo v-bind:value="`ton://transfer/${addressCanonical}`" v-bind:size="300" />
        </ui-modal>

        <address-tabs style="margin-top: 18px;"
            v-bind:address="addressCanonical"
            v-bind:isActive="isActive"/>

        <div style="display: none">
            <a ref="devExplorerLink" target="_blank">View in Toncoin Explorer</a>
        </div>
    </section>
</template>

<script>
import IconQr from '@img/icons/tonscan/qr-14.svg?vue';
import IconTitleHelp from '@primer/octicons/build/svg/info-16.svg?vue';
import { goToDevExplorerMixin } from '~/mixins';
import { getSingleNominatorData, getAddressInfo } from '~/api';
import { canonizeAddress } from '~/tonweb.js';
import { showToast } from '~/toast.js';
import UiQr from '~/components/UiQr.vue';
import AddressTabs from '../address/AddressTabs.vue';

export default {
    props: {
        address: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            poolInfo: {},
            wallet: {},
            qrModalVisible: false,
        };
    },

    watch: {
        address: {
            immediate: true,
            handler: 'canonizeAddress',
        },
    },

    computed: {
        isActive() {
            if (this.wallet.is_active === undefined) {
                return undefined; // keep loading state
            }

            return this.wallet.is_active;
        },
    },

    created() {
        this.loadData();
    },

    methods: {
        async loadData() {
            const [walletInfo, { nominator_pool_single: poolInfo }] = await Promise.all([
                getAddressInfo(this.address),
                getSingleNominatorData(this.address).catch((error) => {
                    if (error.response.status === 400 || error.response.status === 500) {
                        showToast(this.$t('single_nominator.error.redirect_invalid'));
                        this.$router.push(this.$localizeRoute({
                            name: 'address',
                            params: { address: this.address },
                        }));
                    }
                }),
            ]);

            this.poolInfo = poolInfo;
            this.wallet = walletInfo;
        },

        canonizeAddress() {
            this.addressCanonical = canonizeAddress(this.address);
        },
    },

    components: {
        IconTitleHelp, AddressTabs, UiQr, IconQr,
    },

    mixins: [goToDevExplorerMixin],
};
</script>
