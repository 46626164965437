<template>
    <div class="theme-selector" style="flex-direction: row;">
        <div class="theme-selector-box" style="justify-content: center;"
            v-for="source in availableTxSources"
            v-bind:class="{ 'theme-selector-box--selected': selected === source }"
            v-on:click="selected = source"
            v-bind:key="source">

            <span class="theme-selector-box__tick" v-if="selected === source">
                <icon-success class="theme-selector-box__tick__icon"/>
            </span>

            <div class="theme-selector-box__text" style="border: none;" v-html="$t(`settings.tx_source.${source}`)"/>
        </div>
    </div>
</template>

<script>
import { APP_MAIN_TX_SOURCE } from '~/config.js';
import IconSuccess from '@primer/octicons/build/svg/check-16.svg?inline';

export default {
    computed: {
        defaultTxSource() {
            return APP_MAIN_TX_SOURCE;
        },

        availableTxSources() {
            return ['toncenter', 'tonapi'];
        },

        selected: {
            get() {
                return this.$store.state.txTableSource;
            },

            set(source) {
                this.$store.commit('updateTxTableSource', source);
            },
        },
    },

    components: { IconSuccess },
};
</script>
