<template>
    <section>
        <div class="tx-history-wrap desktop-table">
            <table class="ui-table last-blocks-mini-table">
                <thead>
                    <tr>
                        <th v-text="$t('blocks.block')"/>
                        <th v-text="$t('blocks.transactions')"/>
                        <th v-text="$t('common.time')"/>
                    </tr>
                </thead>

                <tbody v-if="blocks.length === 0">
                    <tr v-for="i in 15" v-bind:key="`blocks_mini_table_skeleton_${i}`">
                        <td><span class="skeleton">123456789</span></td>
                        <td><span class="skeleton">3</span></td>
                        <td><span class="skeleton">21:17:22</span></td>
                    </tr>
                </tbody>

                <tbody v-else>
                    <tr class="new-row-animation"
                        v-for="block in blocks"
                        v-bind:key="`${block.workchain}:${block.seqno}`">
                        <td>
                            <ui-link v-bind:to="{ name: 'block', params: { ...block } }">
                                {{block.seqno}}
                            </ui-link>
                        </td>
                        <td v-text="block.tx_count"/>
                        <td>
                            <ui-datetime hide-date v-bind:timestamp="block.gen_utime"/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="show-more-container" v-if="blocks.length > 0">
            <ui-link v-bind:to="{
                name: 'blocks',
                query: { wc: blocks[0].workchain },
            }">
                <button class="show-more-container__button">
                    {{$t('common.show_more')}}
                    <icon-chevron-down style="margin-left: 4px; width: 16px; height:12px;"/>
                </button>
            </ui-link>
        </div>
    </section>
</template>

<script>
import IconChevronDown from '@img/icons/tonscan/chevron-bottom-14.svg?inline';

export default {
    props: {
        blocks: {
            type: Array,
            default: undefined,
        },
    },

    components: {
        IconChevronDown,
    },
};
</script>

<style lang="scss">
.last-blocks-mini-table {
    overflow: hidden;

    td, th {
        text-align: center;
    }
}
</style>
