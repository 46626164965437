<template>
    <div class="card" style="height: 100%; max-width: 100%">
        <div class="card-title" style="border: none">
            {{$t('stats.market_data')}}
            <chart-interval-selector v-model="interval"/>
        </div>

        <div class="data-container">
            <side-ear
                v-bind:param-top="marketData.price"
                v-bind:param-middle="marketData.caps"
                v-bind:param-bottom="marketData.volume"
                v-bind:interval="interval"/>

            <line-chart ref="chart" hide-legend style="flex-grow: 1"
                v-bind:labels="parsedChartLabels"
                v-bind:datasets="parsedChartDatasets"/>
        </div>
    </div>
</template>

<script>
import { getMarketDataHistory } from '~/api/coingecko.js';
import { getBlockchainMarketAnal } from '~/api/extenderContracts.js';
import { prefixNumber } from '~/lib/Chart.js/helpers.js';
import { AMOUNT_OF_DATA_ON_MOBILE, AMOUNT_OF_DATA_ON_TABLET, decimateDataset } from '~/helpers.js';
import { decimateData } from '~/decimation.js';
import LineChart from '~/lib/Chart.js/UiChartLine.vue';
import ChartIntervalSelector, { INTERVAL_TWO_WEEKS } from './ChartIntervalSelector.vue';
import SideEar from './ChartSideEar.vue';

const decimatePrice = (dataset, offset) => {
    const updatedDataset = decimateDataset(dataset, offset);

    return {
        ...updatedDataset,
        data: updatedDataset.data.map((y, idx) => ({ x: idx, y })),
    };
};

export default {
    data() {
        return {
            interval: INTERVAL_TWO_WEEKS,
            labels: undefined,
            datasets: undefined,
            marketData: {
                price: {},
                caps: {},
                volume: {},
            },
            isDataLoading: true,
        };
    },

    watch: {
        interval() {
            this.loadData();
        },
    },

    mounted() {
        setTimeout(() => this.loadData(), 300);
    },

    computed: {
        parsedChartLabels() {
            if (!this.labels) {
                return undefined;
            }

            /* eslint no-else-return: "off" */
            if (this.isMobile) {
                return decimateData(this.labels, AMOUNT_OF_DATA_ON_MOBILE);
            } else if (this.isTablet) {
                return decimateData(this.labels, AMOUNT_OF_DATA_ON_TABLET);
            }

            return this.labels;
        },

        parsedChartDatasets() {
            if (!this.datasets) {
                return undefined;
            }

            const [_priceDataset, _volumeDataset] = this.datasets;
            const color = this.$refs.chart.style.lineColor;

            const priceDataset = {
                ..._priceDataset,
                borderColor: color,
                backgroundGradient: [`${color}50`, `${color}20`, `${color}00`],
            };

            const volumeDataset = {
                ..._volumeDataset,
                backgroundColor: `${color}70`,
            };

            switch (true) {
                case this.isMobile: {
                    return [
                        decimatePrice(priceDataset, AMOUNT_OF_DATA_ON_MOBILE),
                        decimatePrice(volumeDataset, AMOUNT_OF_DATA_ON_MOBILE),
                    ];
                }

                case this.isTablet: {
                    return [
                        decimatePrice(priceDataset, AMOUNT_OF_DATA_ON_TABLET),
                        decimatePrice(volumeDataset, AMOUNT_OF_DATA_ON_TABLET),
                    ];
                }

                default: {
                    return [
                        decimatePrice(priceDataset, 1),
                        decimatePrice(volumeDataset, 1),
                    ];
                }
            }
        },
    },

    methods: {
        updateInterval({ length }) {
            this.interval = length;
        },

        async loadData() {
            const calculate = (dataset, localization) => {
                const latestValue = dataset.at(-1)[1];
                const earliestValue = dataset.at(0)[1];
                const valueDiff = (latestValue - earliestValue) / latestValue;

                return Object.freeze({
                    localization,
                    value: `$ ${prefixNumber(latestValue)}`,
                    change: valueDiff,
                });
            };

            const {
                prices,
                market_caps: marketCaps,
                total_volumes: volumes,
            } = await getMarketDataHistory({
                currency: 'USD',
                days: this.interval,
            });

            // Temporary solution
            // Reason: coingecko is lying
            const marketAnal = await getBlockchainMarketAnal();
            const mc = marketAnal.quotes?.usd?.market_cap;
            marketCaps[marketCaps.length - 1][1] = mc;

            this.marketData.price = calculate(prices, 'stats.price');
            this.marketData.caps = calculate(marketCaps, 'stats.capitalization');
            this.marketData.volume = calculate(volumes, 'stats.trading_volume');

            const labels = prices.map(([timestamp, _]) => timestamp);

            const priceDataset = Object.freeze({
                data: prices.map(([_, value]) => value),
                borderWidth: 1.5,
                fill: true,
                yAxisID: 'y',
                label: this.$t('stats.price'),
                suffix: ' USD',
                parsing: false,
            });

            const volumeDataset = Object.freeze({
                data: volumes.map(([_, value]) => value),
                fill: true,
                type: 'bar',
                yAxisID: 'volume',
                label: this.$t('stats.volume'),
                suffix: ' TON',
                parsing: false,
            });

            this.labels = labels;
            this.datasets = [priceDataset, volumeDataset];
            this.isDataLoading = false;
        },
    },

    components: {
        LineChart,
        SideEar,
        ChartIntervalSelector,
    },
};
</script>
