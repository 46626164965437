<template>
    <div class="card">
        <div class="card-title" style="border: none; padding-bottom: 8px;" v-text="$t('stats.validation_status')"/>

        <table class="validation-status-table">
            <tr>
                <th v-text="$t('stats.election_id')"/>
                <th v-text="$t('stats.round_started')"/>
                <th class="mobile-hidden" v-text="$t('stats.election')"/>
                <th class="mobile-hidden" v-text="$t('stats.delay')"/>
                <th class="mobile-hidden" v-text="$t('stats.validation')"/>
                <th class="mobile-hidden" v-text="$t('stats.hold')"/>
                <th v-text="$t('stats.next_round')"/>
            </tr>

            <tr v-if="cycleStart == 0 || cycleEnd == 0">
                <td v-for="i in 7" v-bind:key="`validation_status_skeleton_${i}`">
                    <div class="skeleton" style="width: 70%; margin-bottom: 0;">01 Jan 2023 at 07:33</div>
                </td>
            </tr>

            <tr v-else>
                <td v-text="cycleStart"/>
                <td>
                    <ui-datetime v-bind:timestamp="holdEnd" hide-seconds/>
                </td>
                <td class="mobile-hidden">
                    <span class="cycle-step-status">Done</span>
                </td>
                <td class="mobile-hidden">
                    <span class="cycle-step-status">Done</span>
                </td>
                <td class="mobile-hidden">
                    <span class="cycle-step-status">Active</span>
                </td>
                <td class="mobile-hidden">
                    <ui-datetime v-bind:timestamp="holdEnd" hide-seconds/>
                </td>
                <td>
                    <ui-datetime v-bind:timestamp="holdEnd" hide-seconds/>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>

export default {
    constants: {
        validatorsElectedFor: 65536,
        electionsStartBefore: 32768,
        electionsEndBefore: 8192,
        stakeHeldFor: 32768,
    },

    props: {
        cycleStart: {
            type: Number,
            required: true,
        },
        cycleEnd: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            roundStart: undefined,
            holdEnd: undefined,
        };
    },

    beforeUpdate() {
        this.roundStart = this.cycleStart - this.$options.constants.electionsStartBefore;
        this.holdEnd = this.cycleEnd + this.$options.constants.stakeHeldFor;
    },
};
</script>

<style lang="scss">
.cycle-step-status {
    color: var(--green-bright);
}

.validation-status-table {
    padding: 0 16px 12px 16px;
    border-spacing: 0;

    th {
        text-align: left;
        font-weight: normal;
        opacity: .4;
        padding: 3px 0;
    }

    td {
        padding: 3px 0;
    }
}

@media screen and (max-width: 600px) {
    .validation-status-table {
        table-layout: fixed;
        width: 100%;
        .mobile-hidden {
            display: none;
        }
    }
}
</style>
