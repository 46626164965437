import { TONAPI_ENDPOINT, TONAPI_KEY } from '~/config.js';
import { canonizeAddress } from '~/tonweb.js';
import { hexToBase64 } from '~/utils.js';
import axios from 'axios';

/**
 * @see https://docs.tonconsole.com/tonapi/api-v2
 */
const http = axios.create({
    baseURL: TONAPI_ENDPOINT,
    headers: {
        Authorization: `Bearer ${TONAPI_KEY}`,
    },
});

/**
 * @param  {String} address
 * @return {Promise<Array>}
 */
export const getJettonBalances = async function getUserJettons(address) {
    const { data: { balances } } = await http.get(`accounts/${address}/jettons`);

    /* eslint camelcase: "off" */
    return balances.map(({ balance, wallet_address, jetton }) => Object.freeze({
        address: canonizeAddress(wallet_address.address),
        jetton_address: canonizeAddress(jetton.address),
        balance,
        jetton_meta: Object.freeze({
            name: jetton?.name,
            symbol: jetton?.symbol,
            description: null,
            image_data: null,
            decimals: jetton?.decimals,
            image: Object.freeze({
                w72: jetton?.image,
                w144: jetton?.image,
                w216: jetton?.image,
            }),
        }),
    }));
};

/**
 * @param  {Object} eventAction
 * @param  {String} msgAccount
 * @return {Object}
 */
const generateMessage = function convertTonapiActionToTonscanMessage(eventAction, msgAccount) {
    const eventName = eventAction.type;
    const eventObj = eventAction[eventName] ?? {};

    // convert CamelCase to snake_case:
    let eventNameFormatted = eventName.match(/[A-Z][a-z]+|[0-9]+/g).join('_').toLowerCase();

    // Exception:
    if (eventNameFormatted === 'un_subscribe') {
        eventNameFormatted = 'unsubscribe';
    }

    // Most of the values are set by default
    // Then, in the switch/case we may change them
    const messages = {
        from: undefined,
        to: undefined,
        action: undefined,
        event: undefined,
        meta: undefined,
        source_alias: undefined,
        destination_alias: undefined,
        message: eventObj?.comment || null,
        is_external: eventName === 'Unknown',
        is_success: eventAction.status === 'ok',
        is_swapped: eventName === 'JettonSwap',
        is_aggregated: false,
        is_bounced: false,
        is_service: false,
        op: null,
    };

    // Don't show message if it starts from 'call:'
    if (messages.message?.startsWith('Call: ')) {
        messages.message = '';
    }

    // eslint-disable-next-line one-var, one-var-declaration-per-line
    let from, to, action, meta;

    let event = eventNameFormatted;
    let sourceAlias = eventObj.sender?.name;
    let destinationAlias = eventObj.recipient?.name;
    let tonAmount = (eventObj?.amount ?? 0).toString();
    const isOut = msgAccount === eventObj.sender?.address;

    switch (eventName) {
        case 'TonTransfer': {
            from = eventObj.sender.address;
            to = eventObj.recipient.address;
            event = msgAccount === from
                ? 'sent_ton'
                : 'received_ton';
            break;
        }

        case 'JettonSwap': {
            messages.amount_in = eventObj.amount_in;
            messages.amount_out = eventObj.amount_out;
            messages.dex = eventObj.dex;
            action = Object.freeze({
                type: 'jetton:swap',
            });
            meta = Object.freeze({
                jetton_in_address: canonizeAddress(eventObj.jetton_master_in?.address || 'Ef9VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVbxn'),
                amount_in: String(eventObj.amount_in || eventObj.ton_in),
                symbol_in: eventObj.jetton_master_in?.symbol || 'TON',
                decimals_in: eventObj.jetton_master_in?.decimals ?? 9,
                jetton_out_address: canonizeAddress(eventObj.jetton_master_out?.address || 'Ef9VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVbxn'),
                amount_out: String(eventObj.amount_out || eventObj.ton_out),
                symbol_out: eventObj.jetton_master_out?.symbol || 'TON',
                decimals_out: eventObj.jetton_master_out?.decimals ?? 9,
            });
            from = eventObj.router.address;
            to = eventObj.user_wallet.address;
            break;
        }

        case 'JettonTransfer': {
            from = eventObj.sender.address;
            to = eventObj.recipient.address;
            action = Object.freeze({
                type: isOut ? 'jetton:transfer' : 'jetton:transfer_notification',
                amount: eventObj.amount,
                sender: canonizeAddress(from),
                destination: canonizeAddress(to),
            });
            meta = Object.freeze({
                jetton: eventObj.jetton,
                jetton_address: canonizeAddress(eventObj.jetton.address),
            });
            event = isOut
                ? 'sent_jetton'
                : 'received_jetton';
            break;
        }

        case 'Subscribe': {
            from = eventObj.subscriber.address;
            to = eventObj.beneficiary.address;
            break;
        }

        case 'UnSubscribe': {
            from = eventObj.subscriber.address;
            to = eventObj.beneficiary.address;
            break;
        }

        case 'NftItemTransfer': {
            action = Object.freeze({
                type: 'nft:transfer_tonapi',
                nft: canonizeAddress(eventObj.nft),
            });
            from = eventObj.sender?.address || eventObj.nft;
            to = eventObj.recipient.address;
            messages.source_type = 'wallet';
            messages.destination_type = 'wallet';
            if (from === eventObj.nft) {
                event = 'deploy_nft';
                break;
            }
            event = msgAccount === from
                ? 'sent_nft'
                : 'received_nft';
            break;
        }

        case 'NftPurchase': {
            action = Object.freeze({
                type: 'nft:transfer_tonapi',
                nft: canonizeAddress(eventObj.nft.address),
            });
            messages.source_type = 'wallet';
            messages.destination_type = 'wallet';
            messages.token = eventObj.amount.token_name;
            from = eventObj.buyer.address;
            to = eventObj.seller.address;
            sourceAlias = eventObj.buyer?.name;
            destinationAlias = eventObj.seller?.name;
            break;
        }

        case 'SmartContractExec': {
            tonAmount = String(eventObj.ton_attached);
            from = eventObj.executor.address;
            to = eventObj.contract.address;
            break;
        }

        case 'ContractDeploy': {
            to = eventObj.address;
            break;
        }

        case 'WithdrawStake': {
            from = eventObj.pool.address;
            to = eventObj.staker.address;
            break;
        }

        case 'DepositStake': {
            from = eventObj.staker.address;
            to = eventObj.pool.address;
            break;
        }

        case 'JettonMint': {
            from = eventObj.jetton.address;
            to = eventObj.recipient.address;
            meta = Object.freeze({
                symbol: eventObj.jetton.symbol,
                decimals: eventObj.jetton.decimals,
            });
            break;
        }

        case 'JettonBurn': {
            from = eventObj.sender.address;
            to = eventObj.jetton.address;
            meta = Object.freeze({
                symbol: eventObj.jetton.symbol,
                jetton: eventObj.jetton,
                jetton_address: canonizeAddress(eventObj.jetton.address),
                decimals: eventObj.jetton.decimals,
            });
            action = Object.freeze({
                type: 'jetton:burn',
                amount: eventObj.amount.toString(),
            });
            break;
        }

        case 'ElectionsDepositStake': {
            from = eventObj.staker.address;
            to = 'Ef8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzM0vF';
            break;
        }

        case 'ElectionsRecoverStake': {
            from = 'Ef8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzM0vF';
            to = eventObj.staker.address;
            break;
        }

        case 'WithdrawStakeRequest': {
            from = eventObj.staker.address;
            to = eventObj.pool.address;
            break;
        }

        case 'AuctionBid': {
            meta = Object.freeze({
                symbol: eventObj.amount.token_name,
            });
            from = eventObj.bidder.address;
            to = eventObj.auction.address;
            tonAmount = eventObj.amount.value;
            break;
        }

        case 'Unknown': {
            to = msgAccount;
            from = msgAccount;
            break;
        }

        default:
            // For test only:
            // In the case if we have an event that was not found previously - we can print it in alert

            // window.alert('Found an unknown event: ' + eventName);
            break;
    }

    messages.from = from ? canonizeAddress(from) : null;
    messages.to = canonizeAddress(to);
    messages.amount = tonAmount;
    messages.meta = meta;
    messages.action = action;
    messages.source_alias = sourceAlias;
    messages.destination_alias = destinationAlias;
    messages.event = event;

    return messages;
};

/**
 * @param  {String} address
 * @return {Promise<Array>}
 */
export const getAccountEvents = async function getEventsByAccount(address, params) {
    const { data: response } = await http.get(`accounts/${address}/events`, {
        params,
    });

    const events = response.events.map(event => Object.freeze({
        address: canonizeAddress(event.account.address),
        fee: null,
        hash: hexToBase64(event.event_id),
        lt: String(event.lt),
        timestamp: event.timestamp * 1000,
        messages: event.actions.map(action => generateMessage(action, event.account.address)),
    }));

    return events;
};
