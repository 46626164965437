<template>
    <div class="card" style="height: 100%;max-width: 100%;">
        <div class="card-title" style="border: none;" v-text="$t('stats.address_count')"/>

        <line-chart ref="chart" hide-legend
            v-bind:labels="parsedChartLabels"
            v-bind:datasets="parsedChartDatasets"/>
    </div>
</template>

<script>
import { getStatus } from '~/api/tontech.js';
import { AMOUNT_OF_DATA_ON_MOBILE, AMOUNT_OF_DATA_ON_TABLET, decimateDataset } from '~/helpers.js';
import LineChart from '~/lib/Chart.js/UiChartLine.vue';
import { decimateData } from '~/decimation.js';

export default {
    data() {
        return {
            labels: undefined,
            datasets: undefined,
        };
    },

    mounted() {
        this.getData();
    },

    computed: {
        parsedChartLabels() {
            if (!this.labels) {
                return undefined;
            }

            switch (true) {
                case this.isMobile: return decimateData(this.labels, AMOUNT_OF_DATA_ON_MOBILE);
                case this.isTablet: return decimateData(this.labels, AMOUNT_OF_DATA_ON_TABLET);
                default: return this.labels;
            }
        },

        parsedChartDatasets() {
            if (!this.datasets) {
                return undefined;
            }

            const [_addressCount] = this.datasets;
            const color = this.$refs.chart.style.lineColor;

            const addressCount = {
                ..._addressCount,
                borderColor: color,
                backgroundGradient: [`${color}50`, `${color}20`, `${color}00`],
            };

            switch (true) {
                case this.isMobile: return [decimateDataset(addressCount, AMOUNT_OF_DATA_ON_MOBILE)];
                case this.isTablet: return [decimateDataset(addressCount, AMOUNT_OF_DATA_ON_TABLET)];
                default: return [addressCount];
            }
        },
    },

    methods: {
        async getData() {
            const data = await getStatus().then(list => list.slice(-120));

            const dataset = Object.freeze({
                data: data.map(period => period.total_accounts),
                borderWidth: 1.5,
                fill: true,
            });

            this.labels = data.map(({ timestamp }) => timestamp * 1000);
            this.datasets = [dataset];

            const totalSupply = data.at(-1).total_supply;
            this.$emit('supply', totalSupply);
        },
    },

    components: {
        LineChart,
    },
};
</script>
