<script>
/**
 * This component renders a single svg icon to be displayed on the left
 * of the transaction item in the mobile transaction list.
 */
import IconIn from '@img/icons/tonscan/transactions/tx-in.svg?vue';
import IconOut from '@img/icons/tonscan/transactions/tx-out.svg?vue';
import IconExcesses from '@img/icons/tonscan/transactions/tx-excess.svg?vue';
import IconBurn from '@img/icons/tonscan/transactions/tx-burn.svg?vue';
import IconJettonMint from '@img/icons/tonscan/transactions/tx-jetton-mint.svg?vue';
import IconServiceAction from '@img/icons/tonscan/transactions/tx-update.svg?vue';
import IconInternalTransfer from '@img/icons/tonscan/transactions/tx-route.svg?vue';
import IconNftIn from '@img/icons/tonscan/transactions/tx-nft-in.svg?vue';
import IconNftOut from '@img/icons/tonscan/transactions/tx-nft-out.svg?vue';
import IconNftBuy from '@img/icons/tonscan/transactions/tx-nft-buy.svg?vue';
import IconNftMint from '@img/icons/tonscan/transactions/tx-nft-mint.svg?vue';
import IconConfirmation from '@img/icons/tonscan/transactions/tx-check.svg?vue';
import IconConfirmationDouble from '@img/icons/tonscan/transactions/tx-checks.svg?vue';
import IconSwap from '@img/icons/tonscan/transactions/tx-swap.svg?vue';
import IconContractDeploy from '@img/icons/tonscan/transactions/tx-contract-deploy.svg?vue';
import IconSmartContractExec from '@img/icons/tonscan/transactions/tx-contract.svg?vue';
import IconStake from '@img/icons/tonscan/transactions/tx-stake.svg?vue';
import IconStakeIn from '@img/icons/tonscan/transactions/tx-stake-in.svg?vue';
import IconStakeOut from '@img/icons/tonscan/transactions/tx-stake-out.svg?vue';
import IconStakeDeposit from '@img/icons/tonscan/transactions/tx-stake-deposit.svg?vue';
import IconStakeWithdraw from '@img/icons/tonscan/transactions/tx-stake-withdraw.svg?vue';
import IconAuctionBid from '@img/icons/tonscan/transactions/tx-bid.svg?vue';
import IconSubscribe from '@img/icons/tonscan/transactions/tx-sub.svg?vue';
import IconUnsubscribe from '@img/icons/tonscan/transactions/tx-unsub.svg?vue';

/* eslint-disable "quote-props" */
const tonapiEventToIconMap = Object.freeze({
    'auction_bid': IconAuctionBid,
    'contract_deploy': IconContractDeploy,
    'deploy_nft': IconNftMint,
    'deposit_stake': IconStakeDeposit,
    'elections_deposit_stake': IconStakeOut,
    'elections_recover_stake': IconStakeIn,
    'jetton_mint': IconJettonMint,
    'nft_purchase': IconNftBuy,
    'received_nft': IconNftIn,
    'sent_nft': IconNftOut,
    'smart_contract_exec': IconSmartContractExec,
    'subscribe': IconSubscribe,
    'unsubscribe': IconUnsubscribe,
    'withdraw_stake': IconStakeWithdraw,
    'withdraw_stake_request': IconStakeIn,
});

const tonscanActionToIconMap = Object.freeze({
    'jetton:burn': IconBurn,
    'jetton:burn_notification': IconBurn,
    'jetton:excesses': IconExcesses,
    'jetton:internal_transfer': IconInternalTransfer,
    'jetton:swap': IconSwap,
    'jetton:transfer': IconOut,
    'jetton:transfer_notification': IconIn,
    'nft:excesses': IconExcesses,
    'nft:ownership_assigned': IconNftIn,
    'nft:transfer': IconNftOut,
    'pool:new_stake': IconServiceAction,
    'pool:new_stake_ok': IconConfirmation,
    'pool:process_withdraw_requests': IconServiceAction,
    'pool:recover_stake': IconStake,
    'pool:recover_stake_ok': IconConfirmationDouble,
    'pool:update_validator_set_hash': IconServiceAction,
});

export default {
    render(h) {
        return h(this.getIcon());
    },

    props: {
        actionName: String,
        eventName: String,
        amount: String,
        isOut: Boolean,
    },

    methods: {
        getIcon() {
            let icon = this.eventName in tonapiEventToIconMap
                ? tonapiEventToIconMap[this.eventName]
                : tonscanActionToIconMap[this.actionName];

            // Special case: large amounts and new_stake actions probably means adding new stake:
            if (this.actionName === 'pool:new_stake' && this.amount > 5000000000) {
                icon = IconStake;
            }

            return icon ?? (this.isOut ? IconOut : IconIn);
        },
    },
};
</script>
