<script>
import UiChartLine from './UiChartLine.vue';
import ColorSchemeMixin from './mixins/changeColorScheme.js';

export default {
    props: {
        labels: Array,
        datasets: Array,
        hideLegend: Boolean,
    },

    render(createElement) {
        const props = {
            labels: this.labels,
            datasets: this.datasets,
            hideLegend: this.hideLegend,
            type: 'bar',
        };

        return createElement(UiChartLine, { props });
    },

    mixins: [ColorSchemeMixin],
};
</script>
