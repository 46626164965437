<template>
    <div class="tx-history-wrap desktop-table">
        <table class="tx-table all-transactions-table">
            <thead v-if="!isMobile">
                <tr>
                    <th v-pre width="40"></th>
                    <th>
                        <div class="tx-table__cell" v-text="$t('common.time')"/>
                    </th>
                    <th>
                        <div class="tx-table__cell tx-table__cell--align-right" style="padding-right: 0;"
                            v-text="$t('address.all_tx_table.sender')"/>
                    </th>
                    <th v-pre width="20"></th>
                    <th>
                        <div class="tx-table__cell" style="padding-left: 0;"
                            v-text="$t('address.all_tx_table.recipient')"/>
                    </th>
                    <th>
                        <div class="tx-table__cell tx-table__cell--align-right" v-text="$t('address.tx_table.value')"/>
                    </th>
                </tr>
            </thead>

            <!-- Tables (desktop / mobile) -->
            <template v-if="transactions">
                <component class="new-row-animation"
                    v-bind:is="isMobile ? 'blocks-tx-row-mobile' : 'blocks-tx-row'"
                    v-for="tx in transactions"
                    v-bind:key="tx.hash"
                    v-bind:hash="tx.hash"
                    v-bind:createdAt="tx.created_at"
                    v-bind:from="tx.from"
                    v-bind:to="tx.to"
                    v-bind:isService="tx.is_service"
                    v-bind:isExternal="tx.is_external"
                    v-bind:amount="tx.amount"/>
            </template>

            <!-- Skeletons (desktop / mobile) -->
            <template>
                <component
                    v-bind:is="isMobile ? 'tx-skeleton-mobile' : 'tx-skeleton'"
                    v-for="i in 40"
                    v-bind:key="`block_tx_skeleton_${i}`"/>
            </template>
        </table>

        <ui-mugen-scroll show-button
            v-bind:handler="loadOldTransactions"
            v-bind:shouldHandle="shouldHandleScroll"
            v-bind:isLoading="isLoading"/>
    </div>
</template>

<script>
import { getAllTransactions } from '~/api';
import TxSkeletonMobile from '~/components/address/TxRowSkeletonMobile.vue';
import TxSkeleton from './BlocksTransactionsRowSkeleton.vue';
import BlocksTxRow from './BlocksTransactionsRow.vue';
import BlocksTxRowMobile from './BlocksTransactionsRowMobile.vue';

export default {
    timerHandle: undefined,

    props: {
        workchain: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            transactions: undefined,
            isLoading: false,
            // Whether to keep the size of the block table when new tx appear.
            // Default is true, so that the table won't grow indefinetely if user opens the page and leaves.
            // Should be set to false if user intentionally requests more tx (scrolls down or requests new page).
            truncateTransactionList: true,
        };
    },

    computed: {
        shouldHandleScroll() {
            return !this.isLoading;
        },
    },

    async created() {
        this.transactions = await getAllTransactions({
            wc: this.workchain,
            limit: 10,
        });

        this.$options.timerHandle = setInterval(() => this.loadNewTransactions(), 6000);
    },

    beforeDestroy() {
        clearInterval(this.$options.timerHandle);
    },

    methods: {
        async loadNewTransactions() {
            const lastTxTimestamp = this.transactions[0].created_at + 1;

            const newTxs = await getAllTransactions({
                wc: this.workchain,
                startUtime: lastTxTimestamp,
            });

            const uniqueTransactions = [];

            newTxs.forEach((tx) => {
                const alreadyHasReverseTx = uniqueTransactions.some((transaction) => { // eslint-disable-line arrow-body-style
                    return transaction.created_at === tx.created_at
                        && transaction.amount === tx.amount;
                });

                if (!alreadyHasReverseTx) {
                    uniqueTransactions.push(tx);
                }
            });

            this.transactions = uniqueTransactions
                .sort((a, b) => b.created_at - a.created_at)
                .concat(this.transactions);

            if (this.truncateTransactionList) {
                this.transactions.splice(32);
            }
        },

        async loadOldTransactions() {
            this.truncateTransactionList = false;
            this.isLoading = true;

            const firstTimestamp = this.transactions.at(-1).created_at - 1;

            const oldTxs = await getAllTransactions({
                wc: this.workchain,
                endUtime: firstTimestamp,
            });

            this.transactions = this.transactions.concat(oldTxs);
            this.isLoading = false;
        },
    },

    components: {
        BlocksTxRow, BlocksTxRowMobile, TxSkeleton, TxSkeletonMobile,
    },
};
</script>

<style lang="scss">
.all-transactions-table {
    thead {
        background-color: var(--tx-table-thead-alt-background);
    }
}
</style>
