<template>
    <section>
        <div v-if="!showSkeleton && holders.length === 0">
            <div class="tx-history-empty-panel">
                {{$t('jetton.no_holders')}}
            </div>
        </div>

        <div v-else class="tx-history-wrap">
            <table class="ui-table">
                <thead>
                    <tr>
                        <th v-text="$t('common.address')"/>
                        <th v-text="$t('address.tokens.table_token_balance')"/>
                        <th class="ui-table__cell--align-right" v-text="$t('common.pie')"/>
                    </tr>
                </thead>

                <tbody v-if="showSkeleton">
                    <tr v-for="i in 5" v-bind:key="`jetton_tab_holders_skeleton_${i}`">
                        <td v-pre>
                            <span class="skeleton">SomeCoolBouncableAddress</span>
                        </td>
                        <td v-pre>
                            <span class="skeleton">1000000</span>
                        </td>
                        <td v-pre class="ui-table__cell--align-right">
                            <span class="skeleton">100.00%</span>
                        </td>
                    </tr>
                </tbody>

                <tbody v-else>
                    <tr v-for="holder in holders" v-bind:key="`jetton_tab_holders_item_${address}_${holder.holder_address}`">
                        <td>
                            <ui-address
                                v-bind:address="holder.holder_address"
                                v-bind:alias="addressBook[holder.holder_address]?.alias"/>
                        </td>
                        <td>
                            {{$ton(holder.balance, decimals)}}
                            <span style="margin-left: 4px; opacity: 0.5;" v-text="symbol"/>
                        </td>
                        <td class="ui-table__cell--align-right">
                            {{(holder.balance / supply * 100).toFixed(2)}}%
                        </td>
                    </tr>
                </tbody>
            </table>

            <ui-mugen-scroll
                v-bind:handler="loadData"
                v-bind:shouldHandle="shouldHandleScroll"
                v-bind:showButton="showPreloader"
                v-bind:isLoading="isLoading"/>
        </div>
    </section>
</template>

<script>
import { getJettonHolders } from '~/api/extenderContracts.js';

export default {
    limit: 50,

    props: {
        address: String,
        decimals: Number,
        supply: String,
        symbol: String,
    },

    data() {
        return {
            holders: [],
            isLoading: true,
            hasMore: true,
            addressBook: {},
        };
    },

    computed: {
        shouldHandleScroll() {
            return !this.isLoading
                && this.address
                && this.hasMore
                && this.holders.length > 0;
        },

        showPreloader() {
            return this.address
                && this.holders.length > 0
                && this.hasMore;
        },

        showSkeleton() {
            return this.isLoading
                && this.holders.length === 0;
        },
    },

    created() {
        this.loadData();
    },

    methods: {
        loadData() {
            this.isLoading = true;

            getJettonHolders(this.address, {
                limit: this.$options.limit,
                offset: this.holders.length,
            }).then((response) => {
                this.addressBook = Object.assign(this.addressBook, Object.freeze(response['@addresses']));
                this.holders = this.holders.concat(Object.freeze(response.holders));
                this.hasMore = response.holders.length >= this.$options.limit;
                this.isLoading = false;
            });
        },
    },
};
</script>
