<template>
    <tr class="new-row-animation">
        <td>
            <ui-link class="tx-table-cell-icon" v-bind:to="{ name: 'tx', params: { hash: hash } }">
                <icon-tx-chain fill="none" />
            </ui-link>
        </td>
        <td>
            <div class="tx-table__cell">
                <ui-timeago v-bind:timestamp="createdAt * 1000" />
            </div>
        </td>
        <td>
            <div class="tx-table__cell tx-table__cell--align-right" style="padding-right: 0;">
                <span v-if="from === null" class="muted" style="text-transform: capitalize;"
                    v-text="$t('address.tx_table.external')" />
                <ui-address v-else v-bind:address="from" />
            </div>
        </td>
        <td>
            <span class="tx-table__badge tx-table__badge--service">
                <template v-if="isService || !to">
                    {{$t('address.tx_table.log')}}
                </template>
                <template v-else>
                    {{$t('address.all_tx_table.to')}}
                </template>
            </span>
        </td>
        <td>
            <div class="tx-table__cell" style="padding-left: 0;">
                <span v-if="!to" v-text="$t('address.tx_table.nowhere')" />
                <ui-address v-else v-bind:address="to" />
            </div>
        </td>
        <td>
            <div class="tx-table__cell tx-table__cell--align-right">
                <span v-if="amount === null || isExternal" class="muted" v-text="$t('common.empty')" />
                <span v-else-if="amount">{{ $ton(amount) }} TON</span>
            </div>
        </td>
    </tr>
</template>

<script>
import IconTxChain from '@img/icons/tonscan/chain-16.svg?inline';

export default {
    props: {
        hash: String,
        createdAt: Number,
        from: String,
        to: String,
        isService: Boolean,
        isExternal: Boolean,
        amount: String,
    },

    components: {
        IconTxChain,
    },
};
</script>

<style scoped lang="scss">
.tx-table__badge {
    text-transform: uppercase;
    display: block;
    width: 32px;
    margin: auto;
}
</style>
