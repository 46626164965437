/**
 * @param  {Number} number
 * @return {String}
 */
export const prefixNumber = function reduceNumberLengthWithSiPrefix(number) {
    /* eslint padded-blocks: "off", no-else-return: "off" */
    if (number >= 1000000000) {
        return Math.round(number / 10000000) / 100 + ' B';

    } else if (number >= 1000000) {
        return Math.round(number / 100000) / 10 + ' M';

    } else if (number > 1000) {
        return Math.round(number / 100) / 10 + 'k';

    } else if (number === 0) {
        return '0';

    } else if (number < 10) {
        return number.toFixed(2);
    }

    return number;
};

/**
 * @param  {Object} chartConfig
 * @return {CanvasGradient}
 */
export const createGradientFromContext = function createCanvasGradientInChartGraphArea(chartConfig) {
    const chartArea = chartConfig.chart.chartArea;

    if (!chartArea || !chartConfig.dataset || !chartConfig.dataset.backgroundGradient) {
        return undefined;
    }

    const gradient = chartConfig.chart.ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    const colors = Array.from(chartConfig.dataset.backgroundGradient);

    gradient.addColorStop(0, colors.pop());
    gradient.addColorStop(1, colors.shift());

    // Two colors are already set, we need to distibute the rest between them.
    // Offset one item from the beginning (idx + 1) and onw from the end (length + 1):
    colors.reverse().forEach((color, idx) => {
        gradient.addColorStop((idx + 1) / (colors.length + 1), color);
    });

    return gradient;
};
